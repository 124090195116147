import Link from "next/link";
import { IoGameController } from "react-icons/io5";

export default function Play() {
  return (
    <Link
      href="/games"
      className="hover:brightness-110 transition hidden md:flex
      tracking-wide text-base  px-4 py-3 md:py-2.5 rounded-lg hover:bg-dark-pri/10 "
    >
      <div className="flex items-center gap-x-2 justify-center tracking-wide text-base text-dark-pri">
        <span className="">
          <IoGameController className="text-xl" />
        </span>
        <span className="hidden lg:flex bg-gradient-to-b from-dark-pri to-white bg-clip-text text-transparent">
          Play
        </span>
      </div>
    </Link>
  );
}
