"use client";
import SkeletonLoader from "@/components/ui/SkeletonLoader";
import {
  ARBITRUM_MAINNET,
  BASE_MAINNET,
  ETHEREUM_MAINNET,
  OPTIMISM_MAINNET,
  POLYGON_MAINNET,
} from "@/constants/chains";
import { getWalletNfts } from "@/server/actions/user/ownedNfts";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Tokens from "./Tokens";
import { getNetworkImage } from "@/utils/misc";
import { FloatingDockFilters } from "./DockFilters";

type Props = {
  walletAddress: string;
  setStep: Dispatch<SetStateAction<number>>;
  setNft: Dispatch<any>;
  step: number;
  page: string | undefined;
};

const FetchTokens = ({ walletAddress, setStep, step, setNft, page }: Props) => {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBlockchain, setSelectedBlockchain] = useState(POLYGON_MAINNET);

  useEffect(() => {
    const fetchNfts = async () => {
      setLoading(true);
      const LIMIT = 100;

      try {
        const result = await getWalletNfts(
          walletAddress,
          LIMIT,
          selectedBlockchain.name
        );
        const nfts = result?.ownedNfts || [];
        setNfts(nfts);
      } catch (error) {
        console.error("Error fetching NFTs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNfts();
  }, [walletAddress, selectedBlockchain]);

  const supportedNetworks = [
    POLYGON_MAINNET,
    ETHEREUM_MAINNET,
    BASE_MAINNET,
    OPTIMISM_MAINNET,
    ARBITRUM_MAINNET,
  ];

  const items = supportedNetworks.map((network) => ({
    title: network.name,
    icon: (
      <img
        src={getNetworkImage(network.name.toLowerCase())}
        alt={network.name}
      />
    ),
    onClick: () => setSelectedBlockchain(network),
  }));

  return (
    <>
      <div className="flex justify-center mb-4 mt-4 w-full mx-auto lg:w-[800px] ">
        <FloatingDockFilters
          items={items}
          selectedItem={selectedBlockchain.name}
        />
      </div>
      {loading ? (
        <SkeletonLoader containerShape="mb-6 w-full h-[300px] min-h-[200px] rounded-lg mt-4" />
      ) : (
        <Tokens nfts={nfts} setStep={setStep} step={step} setNft={setNft} />
      )}
    </>
  );
};

export default FetchTokens;
