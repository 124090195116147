import { Dialog, Transition } from "@headlessui/react";
import { motion } from "framer-motion";
import { Fragment } from "react";
import IconCloseX from "../Icons/IconCloseX";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
};

export default function BannerOverlay({ open, setOpen, children }: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={() => setOpen(false)}
            className="fixed inset-0 bg-black/60 transition-opacity"
          />
        </Transition.Child>

        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ duration: 0.2 }}
          className="h-auto shadow-xl shadow-black backdrop-blur-sm w-full fixed top-0 left-0 flex p-3 lg:p-6 items-center 
          justify-center bg-gradient-to-br from-dark-pri via-primary to-primary transition"
        >
          {children}
          <button
            style={{
              zIndex: 9999,
            }}
            onClick={() => setOpen(false)}
            className="absolute top-6 right-6 rounded-md p-1 bg-white/10 hover:bg-white hover:text-primary transition"
          >
            <IconCloseX />
          </button>
        </motion.div>
      </Dialog>
    </Transition.Root>
  );
}
