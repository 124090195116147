"use client";
import Tooltip from "@mui/material/Tooltip";
import { usePathname } from "next/navigation";
import SkeletonLoader from "../SkeletonLoader";

export const SkeletonLoaderBtn = () => {
  return (
    <SkeletonLoader
      containerShape="rounded-lg w-[42px] h-[42px] mx-auto"
      // flex items-center justify-center
    />
  );
};

type Props = {
  children: any;
  tooltip: string;
  onClick?: () => void;
  href: string;
};

export default function Button({ children, tooltip, href }: Props) {
  // Get current URL
  const pathname = usePathname();
  return (
    <Tooltip arrow placement="right" title={tooltip}>
      <a
        href={href}
        target="_self"
        className={`bg-primary/40 ${
          pathname === href ? "!bg-primary/60  !border-dark-pri" : ""
        } shadow-xl rounded-lg border-transparent hover:scale-125 relative border transition-all ease-in-out 
  duration-300  w-[42px] h-[42px] flex items-center justify-center mx-auto`}
      >
        {children}
      </a>
    </Tooltip>
  );
}
