"use client";
import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  modalTitle?: string;
  modalContent: string | React.ReactNode;
  width?: string;
};

export default function Modal({
  open,
  setOpen,
  modalTitle,
  modalContent,
  width = "max-w-xl",
}: Props) {
  const handleInnerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("keydown", handleEscape);

      // Prevent background scrolling on mobile and desktop
      document.body.style.overflow = "hidden";
    } else {
      document.removeEventListener("keydown", handleEscape);

      // Restore body scroll when modal is closed
      document.body.style.overflow = "";
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      // Cleanup if component unmounts while modal is still open
      document.body.style.overflow = "";
    };
  }, [open, setOpen]);

  if (!open) return null;

  return (
    <div
      style={{ zIndex: 120 }}
      className="fixed inset-0 bg-violet-900/20 backdrop-blur-[2px] transition h-full w-full"
      onClick={() => setOpen(false)}
    >
      <div
        className={`container flex items-end md:items-center justify-center h-full mx-auto ${width}`}
      >
        <div
          onClick={handleInnerClick}
          className="relative md:rounded-2xl bg-dark-default neonPurple w-full md:w-auto 
          scrollbar-hide h-[90%] md:h-auto md:max-h-[90%] overflow-y-auto p-8 rounded-t-lg"
        >
          <button
            aria-label="Close Sign In Modal"
            onClick={() => setOpen(false)}
            className="absolute top-4 right-4 rounded-md p-1 bg-white/5 hover:bg-white/10 transition duration-200 ease-in-out"
          >
            <IoMdClose className="hover:text-white/70 text-[24px] cursor-pointer" />
          </button>
          <div className="pt-4" />
          <div className="flex flex-col">
            {modalTitle && (
              <h1 className="text-3xl mb-3 font-bold tracking-tight text-black">
                {modalTitle}
              </h1>
            )}
            <div>{modalContent}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
