"use client";
import { useLoginModalStore } from "@/components/magic/auth/LoginModal/ModalWrapper";
import { useMagic } from "@/context/MagicProvider";
import { logoutUser } from "@/server/actions/user/auth/logout";
import { getBasicInfo } from "@/server/actions/user/getBasicInfoJWT";
import { logout } from "@/utils/common";
import { motion } from "framer-motion";
import {
  CircleHelp,
  Gem,
  Mail,
  Power,
  Settings,
  Sword,
  Tag,
} from "lucide-react";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { IoGameControllerOutline } from "react-icons/io5";
import { useDisconnect } from "wagmi";
import { create } from "zustand";
import Alert from "../../Alert";
import { useContactModalStore } from "../../ContactForm";
import IconCloseX from "../../Icons/IconCloseX";
import IconHeart from "../../Icons/IconHeart";
import IconMenu from "../../Icons/IconMenu";
import LoadingDots from "../../Loading/LoadingDots";
import { useSellModalStateStore } from "../../SellModal/SellForm";
import {
  fadeInVariant,
  hideNavItemsVariant,
  liVariant,
  mobileMenuVariant,
  ulVariant,
} from "./animation";
import { FORUM, LEGAL_POLICIES } from "./static-links";
import styles from "./style.module.scss";

interface MobileMenuState {
  mobileMenu: boolean;
  setMobileMenu: (searchModal: boolean) => void;
}

export const useMobileMenuStore = create<MobileMenuState>((set) => ({
  mobileMenu: false,
  setMobileMenu: (mobileMenu) => set({ mobileMenu }),
}));

const MobileMenu = () => {
  const { mobileMenu, setMobileMenu } = useMobileMenuStore();
  const { magic } = useMagic();
  const { disconnect } = useDisconnect();

  const router = useRouter();

  const [loadingLikes, setLoadingLikes] = useState(false);
  const [loadingMyInventory, setLoadingMyInventory] = useState(false);

  const checkUserLoggedIn = async () => {
    const loggedIn = await magic?.user?.isLoggedIn();
    return loggedIn;
  };

  const handleMyInventory = async () => {
    setLoadingMyInventory(true);
    try {
      const basicInfo = await getBasicInfo();
      if (
        basicInfo &&
        basicInfo.userWallet &&
        basicInfo.userWallet.length > 0
      ) {
        router.push(`/profile/${basicInfo.userWallet}`);
      } else {
        const isUserLoggedIn = await checkUserLoggedIn();

        if (isUserLoggedIn) {
          const userData = await magic?.user.getMetadata();
          const publicAddress = userData?.publicAddress || "";
          router.push(`/profile/${publicAddress}`);
        } else {
          router.push("/search");
        }
      }
    } catch (error) {
      console.error("Error navigating to inventory:", error);
    } finally {
      setMobileMenu(false);
      setLoadingMyInventory(false);
    }
  };

  const handleLikes = async () => {
    setLoadingLikes(true);
    try {
      const basicInfo = await getBasicInfo();
      if (
        basicInfo &&
        basicInfo.userWallet &&
        basicInfo.userWallet.length > 0
      ) {
        router.push(`/likes`);
      } else {
        const isUserLoggedIn = await checkUserLoggedIn();
        if (isUserLoggedIn) {
          router.push(`/likes`);
        } else {
          setOpenLoginModal(true);
        }
      }
    } catch (error) {
      console.error("Error navigating to likes:", error);
    } finally {
      setMobileMenu(false);
      setLoadingLikes(false);
    }
  };

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertType, setAlertType] = useState("default");
  const { setOpenLoginModal } = useLoginModalStore();

  const handleLinkClick = (href: string) => {
    router.push(href);
    setMobileMenu(false);
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      await logout(magic);
      disconnect();
      window.open("/", "_self");
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const { setOpen } = useSellModalStateStore();

  const MOBILE_NAV_ITEMS = [
    {
      id: 0,
      navTitle: (
        <>
          <Sword className="rotate-[70deg] mx-auto" /> Inventory
        </>
      ),
      onclick: handleMyInventory,
    },
    {
      id: 1,
      navTitle: (
        <>
          <IoGameControllerOutline className="text-xl mx-auto" /> Play Games
        </>
      ),
      href: "/games",
    },
    {
      id: 2,
      navTitle: (
        <>
          <Tag className="mx-auto" /> Sell
        </>
      ),
      href: "/sell",
    },

    {
      id: 3,
      navTitle: (
        <>
          <Gem className="text-xl mx-auto" />
          Rewards
        </>
      ),
      href: "/rewards",
    },

    {
      id: 4,
      navTitle: (
        <>
          <span className="mx-auto">
            <IconHeart />
          </span>
          Likes
        </>
      ),
      onclick: handleLikes,
    },
    {
      id: 5,
      navTitle: (
        <>
          <Settings className="mx-auto" /> Settings
        </>
      ),
      href: "/settings",
    },

    {
      id: 7,
      navTitle: (
        <>
          <Mail className="mx-auto" />
          Contact us
        </>
      ),
      href: "/contact",
    },
    {
      id: 6,
      navTitle: (
        <>
          <CircleHelp className="mx-auto" />
          Help
        </>
      ),
      href: "https://help.rodeyo.com/",
    },
    // {
    //   id: 5,
    //   navTitle: "Giveaways",
    //   onclick: handleClickGiveaway,
    // },
  ];

  return (
    <>
      <motion.button
        variants={hideNavItemsVariant}
        onClick={() => setMobileMenu(true)}
        className="p-2 cursor-pointer sm:hidden"
      >
        <IconMenu />
      </motion.button>
      <motion.nav
        initial="closed"
        animate={mobileMenu ? "opened" : "closed"}
        className="sm:hidden"
      >
        <div className={styles.menuContainer}></div>
        {loadingMyInventory || (loadingLikes && <LoadingDots />)}
        <motion.div variants={mobileMenuVariant} className={styles.mobileMenu}>
          <motion.button
            variants={fadeInVariant}
            onClick={() => setMobileMenu(false)}
          >
            <IconCloseX />
          </motion.button>

          <motion.ul
            variants={ulVariant}
            className="grid grid-cols-2 gap-3 mx-3 mt-6 "
          >
            {MOBILE_NAV_ITEMS.map((navItem) => (
              <motion.li
                whileTap={{ scale: 0.95 }}
                key={navItem.id}
                className=""
              >
                <motion.div
                  variants={liVariant}
                  className="py-6 flex flex-col items-center justify-center gap-3 text-center 
                  rounded-2xl border border-dark-pri/30 bg-dark-pri/10"
                  onClick={
                    navItem.href
                      ? () => handleLinkClick(navItem.href)
                      : navItem.onclick
                  }
                >
                  {navItem.navTitle}
                </motion.div>
              </motion.li>
            ))}
          </motion.ul>
          <div className="mt-8" />
          <motion.div variants={fadeInVariant} className={styles.contact}>
            <div onClick={handleLogout}>Log out</div>
            {FORUM.map((item) => (
              <div
                key={item.navTitle}
                onClick={() => handleLinkClick(item.href)}
              >
                {item.navTitle}
              </div>
            ))}
          </motion.div>
          <div className="mt-6" />
          <motion.div variants={fadeInVariant} className={styles.contact}>
            {LEGAL_POLICIES.map((policy) => (
              <div
                key={policy.navTitle}
                onClick={() => handleLinkClick(policy.href)}
              >
                {policy.navTitle}
              </div>
            ))}
          </motion.div>
        </motion.div>
      </motion.nav>

      <Alert
        open={snackbar}
        close={() => setSnackbar(false)}
        message={snackbarMessage}
        type={alertType}
      />
    </>
  );
};

export default MobileMenu;
