"use client";
import Link from "next/link";
import React, { useEffect, useState, useRef } from "react";
import Search from "../../Search";

export default function BtnLogo() {
  // Show / Hide based on scroll direction
  const [logoText, setLogoText] = useState(false);
  let lastScrollY = 0;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 100 && currentScrollY > lastScrollY) {
        setLogoText(true);
      } else {
        setLogoText(false);
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* Desktop */}
      <Link href="/" className="hidden md:flex">
        <span className="pl-20 flex items-center font-semibold text-15 cursor-pointer text-white">
          <img
            src="/rodeo-logo.png"
            alt="Rodeyo Logo"
            className="w-[48px] h-[32px] lg:mr-2 object-contain"
          />
          <span>Rodeyo</span>
        </span>
      </Link>
      {/* Mobile */}
      <Link href="/" className="md:hidden ">
        <span className="flex items-center font-semibold text-15 cursor-pointer text-white">
          <img
            src="/rodeo-logo.png"
            alt="Rodeyo Logo"
            className="w-[48px] h-[32px] mr-2 md:mr-0 object-contain"
          />
          <span
            className={`${
              logoText
                ? "opacity-0 -translate-y-full transition-all ease-in-out duration-500"
                : "opacity-100 translate-y-0 transition-all ease-in-out duration-500"
            }`}
          >
            Rodeyo
          </span>
        </span>
      </Link>
      <div
        className={`${
          logoText
            ? "-translate-x-[80px] md:-translate-x-0 transition-all ease-in-out duration-500"
            : "-translate-x-0"
        }`}
      >
        <Search />
      </div>
    </>
  );
}
