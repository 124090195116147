"use client";
import { searchCollections } from "@/server/actions/collection/search/pgSearch";
import { fetchEnsUsername } from "@/server/actions/ens";
import { isEVMAddress } from "@/utils/network";
import LoadingDots from "../Loading/LoadingDots";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import ModalV2 from "../Modal/ModalV2";
import Contact from "./Contact";
import GoDirectToCollection from "./GoDirectToCollection";
import Guides from "./Guides";
import Jokes from "./Jokes";
import PopularSearches from "./PopularSearches";
import RecentSearches from "./RecentSearches";
import Results from "./Results";
import SectionHeading from "./SectionHeading";
import { useSearchModalStore } from ".";

interface CollectionResult {
  name: string;
  image: string;
  address: string;
  network: string;
}

export default function SearchModal() {
  const { searchModal, setSearchModal } = useSearchModalStore();
  const [loading, setLoading] = useState(false);
  const [uniqueCollections, setUniqueCollections] = useState<
    CollectionResult[] | null | any[]
  >([]);

  const [query, setQuery] = useState("");

  // Reset query when modal is closed
  useEffect(() => {
    if (searchModal === false) {
      setQuery("");
    }
  }, [searchModal]);

  const handleSearch = useCallback(
    debounce(async (query: string) => {
      if (query.length >= 3) {
        setLoading(true);
        const collections = await searchCollections(query);
        setUniqueCollections(collections);
        setLoading(false);
      } else {
        setUniqueCollections([]);
        setLoading(false);
      }
    }, 350),
    []
  );

  useEffect(() => {
    if (!query) {
      setUniqueCollections([]);
      setWalletAddress("");
      setLoading(false);
      return;
    }
    if (query.length < 3) {
      setUniqueCollections([]);
      setWalletAddress("");
      setLoading(false);
      return;
    }
    handleSearch(query);
  }, [query, handleSearch]);

  const [walletAddress, setWalletAddress] = useState<string>("");
  const [ensName, setEnsName] = useState<string | null>("");

  useEffect(() => {
    const fetchAndSet = async () => {
      setLoading(true);
      if (query && query.length >= 3) {
        if (isEVMAddress(query)) {
          try {
            const ensNameFetched = await fetchEnsUsername(query);
            setEnsName(ensNameFetched);
            setWalletAddress(query); // only set this after ENS is fetched
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        setUniqueCollections([]);
        setWalletAddress("");
        setLoading(false);
      }
    };

    fetchAndSet();
  }, [query]);

  const handleRecentSearchClick = (searchQuery: string) => {
    setQuery(searchQuery);
    handleSearch(searchQuery);
  };
  return (
    <>
      {searchModal && (
        <ModalV2
          width="!w-full sm:min-w-[600px] md:!max-w-[97%] "
          open={searchModal}
          setOpen={setSearchModal}
          modalContent={
            <>
              <div className="py-8 sm:pt-0 sm:w-auto">
                <div className="grid sm:grid-cols-3">
                  <div className="sm:col-span-2 gap-x-4 sm:pr-6">
                    <div className="relative">
                      <form
                        action={async (formData) => {
                          try {
                            await handleSearch(
                              formData.get("search") as string
                            );
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        className="rounded-md hover:border-dark-pri border border-dark-default-1 w-full relative text-white"
                      >
                        <MagnifyingGlassIcon className="absolute left-3 top-1/2 -translate-y-1/2 w-[22px]" />
                        <label htmlFor="search" className="sr-only">
                          search
                        </label>
                        <input
                          type="text"
                          id="search"
                          name="search"
                          autoFocus={true}
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                          className="text-base pl-12 py-5 w-full bg-transparent ring-0 border-transparent 
                               focus:border-transparent focus:outline-none focus:ring-2 focus:ring-dark-pri text-white placeholder:text-white 
                               rounded-md"
                          placeholder="Search..."
                        />
                      </form>
                      {query && query.length > 3 && (
                        <button
                          onClick={() => setQuery("")}
                          className="absolute right-3 top-1/2 -translate-y-1/2 z-10 cursor-pointer flex h-8 w-8 items-center justify-center rounded-md 
                               p-2 text-light-gray hover:bg-gray-50/10 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      )}
                    </div>

                    {loading && (
                      <div className="flex py-12 items-center justify-center">
                        <LoadingDots />
                      </div>
                    )}

                    <div>
                      {((walletAddress && walletAddress.length > 0) ||
                        (uniqueCollections &&
                          uniqueCollections.length > 0)) && (
                        <Results
                          uniqueCollections={uniqueCollections}
                          query={query}
                          walletAddress={walletAddress}
                          ensName={ensName}
                        />
                      )}

                      {/* @ts-ignore */}
                      <RecentSearches search={handleRecentSearchClick} />

                      <div className="mt-8" />
                      {/* @ts-ignore */}
                      <PopularSearches search={handleRecentSearchClick} />

                      <GoDirectToCollection />
                    </div>
                  </div>

                  <div className="flex flex-col w-full relative sm:border-l border-white/10 sm:pl-4 md:!pl-8 pb-2">
                    {/* For vertical scrolling on mobile */}
                    <div className="w-full mb-8">
                      <Guides />
                    </div>

                    <div className="mb-8">
                      <Contact setSearchModal={setSearchModal} />
                    </div>

                    <SectionHeading title="Bored?" icon="⁉️" />

                    <div className="mt-4">
                      <Jokes />
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        />
      )}
    </>
  );
}
