"use client";
import BannerOverlay from "@/components/ui/BannerOverlay";
import { useMagic } from "@/context/MagicProvider";
import { useAccount } from "wagmi";
import { create } from "zustand";

interface TopUpBannerState {
  openTopUpBanner: boolean;
  setTopUpBanner: (openTopUpBanner: boolean) => void;
  neededFunds: string;
  setNeededFunds: (neededFunds: string) => void;
  currencySymbol: string;
  setCurrencySymbol: (currencySymbol: string) => void;
}

export const useTopUpStore = create<TopUpBannerState>((set) => ({
  openTopUpBanner: false,
  setTopUpBanner: (openTopUpBanner) => set({ openTopUpBanner }),
  neededFunds: "",
  setNeededFunds: (neededFunds) => set({ neededFunds }),
  currencySymbol: "",
  setCurrencySymbol: (currencySymbol) => set({ currencySymbol }),
}));

export default function BannerTopUpFunds({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    openTopUpBanner,
    setTopUpBanner,
    setCurrencySymbol,
    setNeededFunds,
    neededFunds,
    currencySymbol,
  } = useTopUpStore();

  const { magic } = useMagic();
  const { isConnected, address, connector } = useAccount();
  const handleClick = () => {
    setTopUpBanner(false);
    setNeededFunds("");
    setCurrencySymbol("");

    magic?.wallet.showUI();
  };

  return (
    <>
      {openTopUpBanner && (
        <BannerOverlay open={openTopUpBanner} setOpen={setTopUpBanner}>
          <div className="flex items-center justify-center w-full gap-4">
            <div className="grid w-auto justify-center mt-4 ">
              <div className="mt-2  md:mt-0 grid gap-4 lg:gap-6 md:flex items-center gap-x-6 w-full ">
                <div className="text-[16px]">
                  You need {neededFunds ? Number(neededFunds).toFixed(4) : ""}{" "}
                  more {currencySymbol} to complete this purchase.
                </div>
                {isConnected ? (
                  <>{children}</>
                ) : (
                  <button onClick={handleClick}>
                    Top Up Wallet{" "}
                    <span aria-hidden="true" className="ml-2">
                      &rarr;
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </BannerOverlay>
      )}
    </>
  );
}
