"use client";
import { usePathname } from "next/navigation";
import { Suspense, useEffect, useState } from "react";
import BtnLogo from "./BtnLogo";
import MobileMenu from "./MobileMenu";
import Play from "./Play";
import SellBtn from "./SellBtn";

export function Header({ children }: { children: React.ReactNode }) {
  const path = usePathname();
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    let prevScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < prevScrollY) {
        // Scrolling up
        setShowHeader(true);
      } else if (currentScrollY > prevScrollY) {
        // Scrolling down
        setShowHeader(false);
      }
      prevScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`bg-dark-default/40 backdrop-blur-md top-0 shadow-xl z-40 
      w-full fixed pl-3 sm:px-6 xl:px-10  py-3 grid grid-cols-4 
      sm:grid-cols-3 sm:flex items-center justify-between  transition-transform duration-300 ease-in-out
      ${(path === "/create" || path?.includes("/games/")) && "md:hidden"} ${
        showHeader ? "translate-y-0" : "md:-translate-y-full"
      }`}
    >
      <BtnLogo />

      <div
        className="flex items-center gap-x-4 md:gap-x-6 text-sm 
        justify-end col-span-2 sm:col-span-1 "
      >
        <Play />
        <Suspense>
          <SellBtn />
        </Suspense>
        {children}
        <MobileMenu />
      </div>
    </div>
  );
}
