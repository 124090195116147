"use client";
import { BtnRainbowKit } from "@/components/magic/auth/BtnRainbowKit";
import {
  ARBITRUM_MAINNET,
  BASE_MAINNET,
  ETHEREUM_MAINNET,
  OPTIMISM_MAINNET,
  POLYGON_MAINNET,
} from "@/constants/chains";
import { useMagic } from "@/context/MagicProvider";
import { logoutUser } from "@/server/actions/user/auth/logout";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useAccountModal } from "@rainbow-me/rainbowkit";
import { Fragment, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import IconWallet from "../../Icons/IconWallet";
import LoadingDots from "../../Loading/LoadingDots";
import { useLoginModalStore } from "@/components/magic/auth/LoginModal/ModalWrapper";

type Props = {
  wallet?: string;
  avatar?: string;
};

export default function BtnWalletLogin({ wallet, avatar }: Props) {
  const { magic, currentChainId, switchNetwork } = useMagic();
  const [loading, setLoading] = useState(false);

  const networks = [
    {
      label: "Ethereum",
      value: ETHEREUM_MAINNET.chainId,
      image: `/network/${ETHEREUM_MAINNET.chainId}.png`,
      rpcUrl: ETHEREUM_MAINNET.rpcUrl,
    },
    {
      label: "Polygon",
      value: POLYGON_MAINNET.chainId,
      image: `/network/${POLYGON_MAINNET.chainId}.png`,
      rpcUrl: POLYGON_MAINNET.rpcUrl,
    },

    {
      label: "Arbitrum",
      value: ARBITRUM_MAINNET.chainId,
      image: `/network/${ARBITRUM_MAINNET.chainId}.png`,
      rpcUrl: ARBITRUM_MAINNET.rpcUrl,
    },
    {
      label: "Optimism",
      value: OPTIMISM_MAINNET.chainId,
      image: `/network/${OPTIMISM_MAINNET.chainId}.png`,
      rpcUrl: OPTIMISM_MAINNET.rpcUrl,
    },
    {
      label: "Base",
      value: BASE_MAINNET.chainId,
      image: `/network/${BASE_MAINNET.chainId}.png`,
      rpcUrl: BASE_MAINNET.rpcUrl,
    },
  ];

  const [selected, setSelected] = useState(networks[0]);

  const handleNetworkChange = async (selectedItem) => {
    if (selectedItem) {
      await switchNetwork(selectedItem.rpcUrl, selectedItem.value);
      setSelected(selectedItem);
    }
  };

  useEffect(() => {
    const selectedNetwork = networks.find((n) => n.value === currentChainId);
    if (selectedNetwork) {
      setSelected(selectedNetwork);
    }
  }, [currentChainId]);

  const checkUserLoggedIn = async () => {
    const loggedIn = await magic?.user?.isLoggedIn();
    return loggedIn;
  };
  const { setOpenLoginModal } = useLoginModalStore();

  // RainbowKit
  const { openAccountModal } = useAccountModal();
  const { isConnected } = useAccount(); // Get the connection status

  const handleLoginBtnClick = async () => {
    setLoading(true);

    if ((wallet && wallet.length > 0) || openAccountModal) {
      magic?.wallet.showUI();
      setLoading(false);
    } else {
      // Clear basic info cookie if user is not logged in
      await logoutUser();
      setOpenLoginModal(true);
    }

    setLoading(false);
  };

  // const handleLogout = async () => {
  //   await logout(null);
  //   await logoutUser();
  // };

  return (
    <>
      <div className="inline-flex rounded-md shadow-sm group md:min-w-[100px] ">
        {!openAccountModal && (
          <button
            type="button"
            onClick={handleLoginBtnClick}
            className={`sm:flex h-[45px] flex-grow overflow-clip items-center justify-center  border 
        border-transparent px-4 sm:px-6  bg-primary py-2 text-base font-medium 
        hover:brightness-110 focus:outline-none 
        transition ${
          wallet && wallet.length > 0
            ? "rounded-l-lg  w-[64px] sm:w-[120px] "
            : "rounded-lg w-[80px] sm:w-[80px] "
        }  ${!wallet && "rounded-lg w-[80px] "}`}
          >
            <>
              {loading ? (
                <LoadingDots />
              ) : (
                <>
                  {wallet && wallet.length > 0 ? (
                    <span className="w-full sm:flex items-center justify-center sm:gap-x-2 md:min-w-[80px]">
                      <IconWallet className="max-w-[26px] min-w-[26px]" />
                      <span className="hidden sm:flex sm:min-w-[50px]">
                        Wallet
                      </span>
                    </span>
                  ) : (
                    <span className="flex justify-center">Log in</span>
                  )}
                </>
              )}
            </>
          </button>
        )}
        {openAccountModal ? (
          <BtnRainbowKit avatar={avatar} />
        ) : (
          wallet &&
          wallet.length > 0 && (
            <Menu as="div" className="relative -ml-px flex items-center">
              <div className="h-[80%] w-[0.75px] bg-dark-pri" />
              <Menu.Button
                className={`h-[45px]  relative inline-flex group items-center hover:brightness-110 rounded-r-md 
            bg-gradient-to-r from-primary to-dark-pri transition hover:bg-dark-pri/20 px-2 py-3 min-w-[36px] max-w-[36px] sm:max-w-none
            focus:z-10 `}
              >
                <span className="sr-only">Open blockchain options</span>
                <span className="flex items-center">
                  <img
                    src={selected.image}
                    alt=""
                    className="h-5 w-5 min-w-5 object-cover sm:object-contain mr-1 group-hover:scale-105 rounded-full"
                  />
                </span>
                <ChevronDownIcon
                  className="h-4 w-4 opacity-80 hidden sm:flex"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute right-0 z-10 -mr-1 mt-[270px] w-56 origin-top-right rounded-lg bg-dark-default shadow-black/60 shadow-lg ring-1 
          ring-dark-pri/10 ring-opacity-5 focus:outline-none border border-dark-pri/10"
                >
                  <div className="py-1">
                    {networks.map((item) => (
                      <Menu.Item key={item.label}>
                        <div
                          onClick={() => handleNetworkChange(item)}
                          className=" hover:bg-primary transition text-white 
                         px-4 py-2 text-sm flex cursor-pointer items-center gap-x-3"
                        >
                          <img
                            src={item.image}
                            alt=""
                            className="h-5 w-5 flex-shrink-0 rounded-full "
                          />
                          {item.label}
                        </div>
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          )
        )}
      </div>
    </>
  );
}
