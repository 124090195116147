"use client";
import BannerSellError from "@/app/nft/[network]/[address]/[tokenId]/(components)/Sell/Alerts/BannerSellError";
import { useLoginModalStore } from "@/components/magic/auth/LoginModal/ModalWrapper";
import { useMagic } from "@/context/MagicProvider";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";

import LoadingDots from "../Loading/LoadingDots";
import Modal from "../Modal";
import SkeletonLoader from "../SkeletonLoader";
import TextTitle from "../Typography/TextTitle";
import FetchTokens from "./FetchTokens";
import SellForm, { useSellModalStateStore } from "./SellForm";

type Props = {
  btn: React.ReactNode;
};

export default function SellModalGlobal({ btn }: Props) {
  const { magic } = useMagic();
  const { open, setOpen } = useSellModalStateStore();
  const [userWallet, setUserWallet] = useState("");
  const { setOpenLoginModal } = useLoginModalStore();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [nft, setNft] = useState<any>({});

  const { address, isConnected } = useAccount(); // Get wallet address and connection status

  useEffect(() => {
    if (open === false) {
      setStep(1);
      setNft({});
    }
  }, [open]);

  const checkUserLoggedIn = useCallback(async () => {
    const loggedIn = await magic?.user?.isLoggedIn();
    return loggedIn;
  }, [magic]);

  const handleClick = useCallback(async () => {
    setLoading(true);

    let wallet = userWallet;

    if (!wallet) {
      if (isConnected && address) {
        // If connected via RainbowKit/Wagmi
        wallet = address;
        setUserWallet(wallet);
      } else {
        // Check Magic authentication
        const loggedIn = await checkUserLoggedIn();
        if (loggedIn) {
          const userData = await magic?.user.getMetadata();
          wallet = userData?.publicAddress || "";
          setUserWallet(wallet || "");
        }
      }
    }

    if (wallet) {
      setOpen(true);
    } else {
      setOpenLoginModal(true);
    }

    setLoading(false);
  }, [userWallet, isConnected, address, checkUserLoggedIn, magic, setOpen]);

  return (
    <>
      <BannerSellError />
      <button
        aria-label="Sell"
        onClick={handleClick}
        className="flex items-center justify-center"
      >
        {loading ? <LoadingDots /> : btn}
      </button>

      {/* Sell Modal */}
      <Modal
        open={open}
        setOpen={setOpen}
        width="w-full  lg:max-w-[70%]  lg:w-[850px] lg:max-h-[90vh] overflow-y-scroll scrollbar-hide"
      >
        <Suspense fallback={<SkeletonLoader />}>
          {step === 1 ? (
            <>
              <TextTitle
                title="Select an item to Sell"
                icon="👇"
                gradientSubtitle="Your Inventory"
              />
              <FetchTokens
                walletAddress={userWallet}
                page="1"
                setStep={setStep}
                setNft={setNft}
                step={step}
              />
            </>
          ) : (
            <>
              <div className="max-w-[90%] mx-auto text-center lg:max-w-[360px]">
                <div className="">
                  <div className="mt-5 justify-center text-center text-3xl font-extrabold md:text-[28px] flex items-center gap-x-2">
                    <span className="from-white to-light-gray bg-clip-text bg-gradient-to-r text-transparent leading-[40px]">
                      {`Sell ${
                        nft?.name
                          ? nft?.name
                          : `# ${nft?.tokenId || nft?.token_id}`
                      }`}
                    </span>
                  </div>
                </div>
              </div>
              <SellForm
                nftName={nft?.name || `# ${nft?.tokenId || nft?.token_id}`}
                nftImage={nft?.image?.cachedUrl}
                userWallet={userWallet || ""}
                tokenNetwork={nft?.network}
                collectionAddress={nft?.contract?.address || nft?.address}
                tokenId={nft?.tokenId || nft?.token_id}
                tokenErc={nft?.tokenType || nft?.token_type}
                collectionImage={nft.contract.openSeaMetadata.imageUrl}
                collectionName={
                  nft.contract.openSeaMetadata.collectionName ||
                  nft.contract.name
                }
                setStep={setStep}
              />
            </>
          )}
        </Suspense>
      </Modal>
    </>
  );
}
